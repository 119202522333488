import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  root: {
    maxWidth: "1440px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
})

const PageWrapper = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.root}>{children}</div>
}

export default PageWrapper
