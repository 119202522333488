import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "src/layout/layout"
import PageWrapper from "src/components/page-wrapper"
import SEO from "src/components/seo"
import { Typography } from "@material-ui/core"
import ButtonBorderlessArrow from "src/components/button-borderless-arrow"

const useStyles = makeStyles({
  root: {
    width: "85%",
    margin: "0 auto",
    paddingTop: "84px",
  },
  rootContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "36px",
  },
  mainContentContainer: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    marginBottom: "48px",
    "@media (max-width:768px)": {
      width: "100%",
      marginTop: "24px",
    },
  },
  links: {
    textDecoration: "underline",
  },
  header: {
    display: "inline-block",
  },
  headerContainer: {
    margin: "64px 0",
  },
})

export default function NotFoundPage({ location }) {
  const classes = useStyles()

  return (
    <>
      <Layout location={location}>
        <SEO title="404 Page" />
        <PageWrapper>
          <div className={classes.root}>
            <div className={classes.rootContainer}>
              <div className={classes.headerContainer}>
                <Typography className={classes.header} variant="h3">
                  We couldn't find that page
                </Typography>
                <br />
                <ButtonBorderlessArrow label={"Return home"} linkUrl={"/"} />
              </div>
            </div>
          </div>
        </PageWrapper>
      </Layout>
    </>
  )
}
