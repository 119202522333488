import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import ButtonBase from "@material-ui/core/ButtonBase"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    display: "block",
    marginTop: "16px",
  },
  buttonText: {
    color: theme.palette.primary.main,
    fontSize: "1.125rem",
    fontWeight: 700,
    "@media (max-width:768px)": {
      fontSize: "1rem",
    },
    "&:after": {
      paddingLeft: "16px",
      position: "absolute",
      content: '"\\2794"',
      color: theme.palette.secondary.main,
      transition: "transform 0.3s ease-out",
    },
    "&:hover, &:focus": {
      color: theme.palette.secondary.main,
      "&:after": {
        transform: "translate(12px)",
      },
    },
  },
}))

const ButtonBorderlessArrow = ({ label, linkUrl, onClickUrl = null }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ButtonBase>
        {onClickUrl ? (
          <a
            href={onClickUrl}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Typography className={classes.buttonText}>{label}</Typography>
          </a>
        ) : (
          <Link to={linkUrl} style={{ textDecoration: "none" }}>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              className={classes.buttonText}
            >
              {label}
            </Typography>
          </Link>
        )}
      </ButtonBase>
    </div>
  )
}

export default ButtonBorderlessArrow
